<template>
  <div>
    <!-- <b-form-group
      label="Nombre"
      label-for="input-title"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-title"
        v-model="$v.question_test.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-description"
        v-model="$v.question_test.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-score-feedback"
        >Este campo es opcional.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <InstrumentForm
      :Instrument="question_test"
      :evaluation_id="evaluation_id"
      :TestForm="TestForm"
      @created="save"
      @update_file="updateFile"
      @delete="askForDeleteQuestionTest"
      :allows_crud="allows_crud"
      :show_delete_button="show_delete_button"
    ></InstrumentForm>
    <!-- @instrumentStateChanged="emitStateChanged" -->
    <!-- Botones -->
    <!-- <div class="row">
      <div
        v-if="allows_crud && show_delete_button"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteQuestionTest"
          >Eliminar</b-button
        >
      </div>
      <div v-if="allows_crud" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "QuestionForm",
  // mixins: [validationMixin],
  components: {
    InstrumentForm: () => import("../InstrumentForm"),
  },
  props: {
    QuestionTest: {
      type: Object,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    TestForm: {
      type: Boolean,
      required: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      question_test: {
        id: this.QuestionTest ? this.QuestionTest.id : generateUniqueId(),
        evaluation: this.QuestionTest
          ? this.QuestionTest.evaluation
          : this.evaluation_id,
        title: this.QuestionTest ? this.QuestionTest.title : "",
        description: this.QuestionTest ? this.QuestionTest.description : "",
        score: this.QuestionTest ? this.QuestionTest.score : null,
        sub_type: this.QuestionTest ? this.QuestionTest.sub_type : null,
        procedure_evidences: this.QuestionTest
          ? this.QuestionTest.procedure_evidences
          : [],
        start_date: this.QuestionTest
          ? this.QuestionTest.start_date
          : this.$moment(),
        due_date: this.QuestionTest
          ? this.QuestionTest.due_date
          : this.$moment().add(2, "hours"),
        publish_date: this.QuestionTest ? this.QuestionTest.publish_date : null,
        publish: this.QuestionTest ? this.QuestionTest.publish : false,
        retake_instrument: this.QuestionTest
          ? this.QuestionTest.retake_instrument
          : false,
        retake_instrument_attempts: this.QuestionTest
          ? this.QuestionTest.retake_instrument_attempts
          : null,
        show_attached_before_start_date: this.Instrument
          ? this.Instrument.show_attached_before_start_date
          : false,
        attachments: this.QuestionTest ? this.QuestionTest.attachments : [],
      },
    };
  },
  // validations() {
  //   return {
  //     question_test: {
  //       title: { required },
  //       description: {},
  //     },
  //   };
  // },
  computed: {
    ...mapGetters({
      evaluations: names.EVALUATIONS,
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
  },
  methods: {
    // validateState(key) {
    //   const { $dirty, $error } = this.$v.question_test[key];
    //   return $dirty ? !$error : null;
    // },
    updateFile(file) {
      if (!isNaN(this.question_test.id)) {
        this.question_test.attachments.push(file.id);
        this.$store.dispatch(names.PATCH_QUESTION_TEST, {
          question_test_id: this.question_test.id,
          item: {
            attachments: this.question_test.attachments,
          },
        });
      }
    },
    save(instrument) {
      this.question_test = instrument;
      if (isNaN(this.question_test.id)) this.createQuestionTest();
      else this.updateQuestionTest();
    },
    createQuestionTest() {
      this.$store
        .dispatch(names.POST_QUESTION_TEST, this.question_test)
        .then((response) => {
          this.question_test = response;
          this.$emit("created", response);
          toast("Instrumento creado.");
        });
    },
    updateQuestionTest() {
      this.$store
        .dispatch(names.UPDATE_QUESTION_TEST, this.question_test)
        .then((response) => {
          this.question_test = response;
          this.$emit("updated", this.question_test);
          toast("Instrumento actualizado.");
        });
    },
    askForDeleteQuestionTest() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Instrumento?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.deleteQuestionTest();
        }
      });
    },
    deleteQuestionTest() {
      this.$store
        .dispatch(names.DELETE_QUESTION_TEST, this.question_test.id)
        .then(() => {
          this.$emit("deleted", this.question_test);
          toast("Instrumento eliminado.");
        });
    },
    // emitStateChanged(state) {
    //   if (state) {

    //   }
    //   this.$emit("stateChanged", state);
    // },
  },
  created() {
    this.$store.dispatch(names.FETCH_EVALUATION, this.evaluation_id);
  },
};
</script>

<style>
</style>